import { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { useIntl } from "react-intl";
import { TLanguages, useTranslate } from "../../contexts/TranslateContext";
import "./styles.scss";

export type Props = {
  onClickHome: () => void;
  onClickAbout: () => void;
  onClickChallenges: () => void;
  onClickCompanies: () => void;
  onClickContact: () => void;
};

export default function NavBar({
  onClickHome,
  onClickAbout,
  onClickChallenges,
  onClickCompanies,
  onClickContact,
}: Props) {
  const intl = useIntl();
  const { locale, setLanguage } = useTranslate();
  const [open, setOpen] = useState(false);

  function handleSelect(item: TLanguages) {
    setLanguage(item);
    setOpen(false);
  }

  return (
    <Navbar
      expand="lg"
      fixed="top"
      className="navbar-background navbar navbar-expand-lg"
    >
      <a className="navbar-logo" href="/">
        <img src="../images/Logos/Grupo4.png" alt="Open Plataforma" />
      </a>
      <div className="menu-itens-desktop">
        <Nav.Link className="menu-text" onClick={onClickHome}>
          {intl.formatMessage({ id: "navbar_home" })}
        </Nav.Link>
        <Nav.Link className="menu-text" onClick={onClickAbout}>
          {intl.formatMessage({ id: "navbar_about" })}
        </Nav.Link>
        <Nav.Link className="menu-text" onClick={onClickChallenges}>
          {intl.formatMessage({ id: "navbar_challenges" })}
        </Nav.Link>
        <Nav.Link className="menu-text" onClick={onClickCompanies}>
          {intl.formatMessage({ id: "navbar_enterprises" })}
        </Nav.Link>
        <Nav.Link className="menu-text" onClick={onClickContact}>
          {intl.formatMessage({ id: "navbar_contact" })}
        </Nav.Link>
        <Nav.Item>
          <div className="menu-language" onMouseLeave={() => setOpen(false)}>
            <button
              className="menu-button"
              onClick={() => setOpen(!open)}
              aria-haspopup="true"
              aria-expanded={open}
              tabIndex={0}
            >
              <span>{locale}</span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={open ? "menu-open" : ""}
              >
                <path
                  d="M12.6666 5.99988L7.99998 10.6665L3.33331 5.99988"
                  stroke="#37474F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {open && (
              <div className="menu-select">
                <button
                  onClick={() => handleSelect("pt")}
                  role="menuitem"
                  aria-label={intl.formatMessage({
                    id: "navbar_portuguese",
                  })}
                >
                  PT
                </button>
                <button
                  onClick={() => handleSelect("es")}
                  role="menuitem"
                  aria-label={intl.formatMessage({
                    id: "navbar_spanish",
                  })}
                >
                  ES
                </button>
              </div>
            )}
          </div>
        </Nav.Item>
      </div>
      <div className="menu-itens-mobile">
        <FaBars />
      </div>
    </Navbar>
  );
}
