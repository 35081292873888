import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Dropdown } from "../../../../components/Dropdown";
import api from "../../../../services/api";
import "./styles.scss";

function AccordionForm() {
  const { slug, challengeID, startupID } = useParams<any>();
  const [challenges, setChallenges] = useState<any>();
  const [maturities, setMaturities] = useState<any>();
  const [selectedChallenge, setSelectedChallenge] = useState<any>();

  const intl = useIntl();

  async function getCompanyChallenges(id: number) {
    var bodyFormData: any = new FormData();
    bodyFormData.append("id", id);

    await api()
      .post(`/company-challenges?slug=${slug}`, bodyFormData)
      .then((response) => {
        const challenge = response.data.find((element: any) => {
          return element.id === Number(challengeID);
        });
        setSelectedChallenge(challenge);
      })
      .catch((response) => {
        console.error(response.data);
      });
  }

  async function getCompany() {
    await api()
      .post(`/company?slug=${slug}`)
      .then((response) => {
        getCompanyChallenges(response.data[0].id);
      })
      .catch((response) => {
        console.error(response.data);
      });
  }

  async function getAllChallenges() {
    await api()
      .get("/open-challenges")
      .then((response) => {
        let desafios: any = [];
        response.data.map((element: any) => {
          if (element.slug === slug) {
            element.desafios.map((desafio: any) => {
              if (desafio.id !== Number(challengeID)) {
                desafios.push(desafio);
              }
            });
            element.desafios = desafios;
          }
        });
        setChallenges(response.data);
      })
      .catch((response) => {
        console.error(response.data);
      });
  }

  function getMaturities() {
    api()
      .get("/maturities")
      .then((response: any) => {
        setMaturities(response.data);
      })
      .catch((response: any) => {
        console.error(response.data);
      });
  }

  useEffect(() => {
    getCompany();
    getAllChallenges();
    getMaturities();
  }, []);

  return (
    <div className="accordion-form">
      <h1>{intl.formatMessage({ id: "accordion_title" })}</h1>
      <div className="challenge-register">
        {selectedChallenge && (
          <Dropdown
            startup={startupID}
            challenge={challengeID}
            title={selectedChallenge.title}
            open="0"
            maturities={maturities}
            onChange={(event: any) => {
              test(event.target.value);
            }}
          />
        )}
      </div>

      {/* <div className="open-challenges-register">
        <h1>Desafios Abertos</h1>
        <div className="other-challenges-register">
          {challenges &&
            challenges.map((challenge: any, index: number) => {
              return (
                <div className="container" key={index}>
                  <p className="company-name">{challenge.title}</p>
                  {challenge.desafios.map((desafio: any, index: number) => {
                    return (
                      <Dropdown
                        key={index}
                        startup={startupID}
                        challenge={desafio.id}
                        title={desafio.title}
                        open="1"
                        maturities={maturities}
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div> */}
    </div>
  );
}

export default AccordionForm;
