const es = [
  {
    value: 0,
    label: "Pre semilla (validación del modelo de negocio)",
  },
  {
    value: 1,
    label: "Semilla (desarrollo de productos y crecimiento de equipos)",
  },
  {
    value: 2,
    label:
      "Serie A (atracción de nuevos consumidores y desarrollo de nuevos productos)",
  },
  {
    value: 3,
    label: "Série B (aceleración de la tasa de crecimiento)",
  },
  {
    value: 4,
    label: "Serie C (crecimiento acelerado y consolidación)",
  },
];

const pt = [
  {
    value: 0,
    label: "Pré seed (validação do modelo de negócios)",
  },
  {
    value: 1,
    label: "Seed (desenvolvimento do produto e crescimento da equipe)",
  },
  {
    value: 2,
    label:
      "Série A (captação de novos consumidores e desenvolvimento de novos produtos)",
  },
  {
    value: 3,
    label: "Série B (aceleração do ritmo de crescimento)",
  },
  {
    value: 4,
    label: "Série C (crescimento acelerado e consolidação)",
  },
];

export const capturingPhase = {
  es,
  pt,
};
