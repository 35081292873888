import './styles.scss';
import { ColumnProps } from '../../types/types';

export default function Column({
  children,
  centered = false,
  padding,
  height
}: ColumnProps) {
  return (
    <div style={{ alignItems: centered ? 'center' : 'start', padding, height }} className="container-column">
      {children}
    </div>
  );
}
