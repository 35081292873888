import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Column from "../../../../components/Column";
import OpenChallengesCard from "../../../../components/OpenChallengesCard";
import Row from "../../../../components/Row";
import { useTranslate } from "../../../../contexts/TranslateContext";
import api from "../../../../services/api";
import { RedirectProps } from "../../../../types/types";
import "./styles.scss";

function OpenChallenges({ innerRef }: RedirectProps) {
  const [openChallenges, setOpenChallenges] = useState<any>([]);

  const intl = useIntl();
  const { locale } = useTranslate();

  const getData = async () => {
    try {
      const response = await api().get("/open-challenges");

      const esChallenges = response.data.filter((challenge: any) =>
        challenge.name.startsWith("(ES)")
      );

      const ptChallenges = response.data.filter(
        (challenge: any) => !challenge.name.startsWith("(ES)")
      );

      setOpenChallenges(locale === "es" ? esChallenges : ptChallenges);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    getData();
  }, [locale]);

  return (
    <div className="open-challenges" ref={innerRef}>
      <h1>{intl.formatMessage({ id: "openChallenges_title" })}</h1>
      <Row>
        {openChallenges.map((element: any, index: number) => {
          return (
            <Column key={index}>
              <OpenChallengesCard
                banner={element.banner}
                logo={element.logo}
                title={element.title}
                subtitle={element.subtitle}
                description={element.description}
                slug={element.slug}
              />
            </Column>
          );
        })}
      </Row>
    </div>
  );
}

export default OpenChallenges;
