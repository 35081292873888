import Banner from '../../components/Banner';
import AccordionForm from './components/AccordionForm';
import Footer from '../../components/Footer';

function ChallengesRegister() {

    return(
        <div className="challenges-register">
            <Banner/>
            <AccordionForm/>
            <Footer backHome={true}/>
        </div>
    )
}

export default ChallengesRegister;