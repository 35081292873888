import React, { useRef } from 'react';
import './styles.scss';
import Header from './components/Header';
import Banner from '../../components/Banner'
import About from './components/About';
import HowItWorks from './components/HowItWorks';
import OpenChallenges from './components/OpenChallenges';
import ClosedChallenges from './components/ClosedChallenges';
import Companies from './components/Companies';
import Contact from './components/Contact';
import Footer from '../../components/Footer';


function Home() {
    
    const homeSection = useRef<HTMLDivElement>(null);
    const aboutSection = useRef<null | HTMLDivElement>(null);
    const challengesSection = useRef<null | HTMLDivElement>(null);
    const companiesSection = useRef<null | HTMLDivElement>(null);
    const contactSection = useRef<null | HTMLDivElement>(null);

    return (
        <div className="home">
            <Header
                homeSection={homeSection}
                aboutSection={aboutSection}
                challengesSection={challengesSection}
                companiesSection={companiesSection}
                contactSection={contactSection}
            />
            <Banner innerRef={homeSection} />
            <About innerRef={aboutSection}/>
            <HowItWorks />
            <OpenChallenges innerRef={challengesSection} />
            <ClosedChallenges />
            <Companies innerRef={companiesSection} />
            <Contact innerRef={contactSection} />
            <Footer />
        </div>
    )
}

export default Home;
