import { useIntl } from "react-intl";
import { useTranslate } from "../../../../contexts/TranslateContext";
import "./styles.scss";

function GeneralInformation({ who_participates, benefits }: any) {
  const intl = useIntl();
  const { locale } = useTranslate();

  return (
    <div className="general-information">
      <h1>
        {intl.formatMessage({
          id: "generalInformation",
        })}
      </h1>
      <div className="who-participates">
        <h1>
          {intl.formatMessage({
            id: "generalInformation_whoParticipate",
          })}
        </h1>
        <p dangerouslySetInnerHTML={{ __html: who_participates }}></p>
      </div>

      <div className="benefits">
        <h1>
          {intl.formatMessage({
            id: "generalInformation_benefits",
          })}
        </h1>
        <p dangerouslySetInnerHTML={{ __html: benefits }}></p>
      </div>

      <div className="questions">
        <p>
          {intl.formatMessage({
            id: "generalInformation_questions",
          })}
        </p>
      </div>

      <div className="notice">
        <p>
          {intl.formatMessage({
            id: "generalInformation_notice",
          })}
        </p>
        <a
          href={
            locale === "pt"
              ? "../images/Edital - Startups.pdf"
              : "../images/esp_Edital - Startups.pdf"
          }
          download={
            locale === "pt"
              ? "Edital - Startups.pdf"
              : "esp_Edital - Startups.pdf"
          }
          role="button"
          className="link"
        >
          <div className="challenges-button">
            {intl.formatMessage({ id: "generalInformation_notice_button" })}
          </div>
        </a>
      </div>
    </div>
  );
}

export default GeneralInformation;
