import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "../../../../components/Alert";
import Input from "../../../../components/Input";
import { emailRegex } from "../../../../constants/regex";
import api from "../../../../services/api";
import "./styles.scss";

export type form = {
  email: string;
  token: string;
};

const formData: form = {
  email: "",
  token: "",
};

export type alert = {
  title: string;
  text: string;
  show: boolean;
  kind: string;
};

const typeData: alert = {
  title: "",
  text: "",
  show: false,
  kind: "",
};

function TokenModal({ show, onHide, challengeid }: any) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(formData);
  const [waitingToken, setWaitingToken] = useState(false);
  const [setAlert, setSetAlert] = useState(typeData);
  const [hasError, setHasError] = useState(false);

  function handleFormEmail() {
    var bodyFormData = new FormData();
    bodyFormData.append("email", form.email);

    if (form.email && !isEmailValid) return setHasError(true);

    api()
      .post(`/startup/token/generate?slug=${slug}`, bodyFormData)
      .then((response: any) => {
        if (response.data.response === "notRegistered") {
          navigate(`/startupregister/${slug}&${challengeid}`);
        } else {
          setWaitingToken(true);
        }
      })
      .catch((error: any) => {
        setSetAlert({
          ...setAlert,
          title: "Erro",
          text: error.data,
          kind: "error",
          show: true,
        });
      });
  }

  function handleFormToken() {
    setWaitingToken(false);

    var bodyFormData = new FormData();
    bodyFormData.append("email", form.email);
    bodyFormData.append("token", form.token);

    api()
      .post(`/startup/token/verify?slug=${slug}`, bodyFormData)
      .then((response: any) => {
        navigate(
          `/challengesregister/${slug}&${challengeid}&${response.data.startup.id}`
        );
      })
      .catch((error: any) => {
        setSetAlert({
          ...setAlert,
          title: "Erro",
          text: error.data,
          kind: "error",
          show: true,
        });
      });
  }

  function handleAlert() {
    setSetAlert({ ...setAlert, show: !setAlert.show });
  }

  const isEmailValid = emailRegex.test(form.email);

  const intl = useIntl();

  return (
    show && (
      <div style={{ width: "100vw" }}>
        <Alert
          title={setAlert.title}
          text={setAlert.text}
          show={setAlert.show}
          kind={setAlert.kind}
          onClick={handleAlert}
        />
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="token-modal-body">
            <h1>{intl.formatMessage({ id: "tokenModal_title" })}</h1>
            <p>{intl.formatMessage({ id: "tokenModal_subtitle" })}</p>
            <Container>
              {!waitingToken ? (
                <>
                  <Input
                    type="email"
                    name="email"
                    label={intl.formatMessage({ id: "tokenModal_input" })}
                    message={intl.formatMessage({
                      id: "tokenModal_inputMessage",
                    })}
                    value={form.email}
                    onChange={(event: any) => {
                      if (isEmailValid) setHasError(false);

                      setForm({ ...form, email: event.target.value });
                    }}
                    hasError={hasError}
                  />

                  <Row className="token-form-row">
                    <Col xs={3} md={3}>
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="button"
                        onClick={handleFormEmail}
                      >
                        {intl.formatMessage({ id: "tokenModal_button" })}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Input
                    type="token"
                    name="token"
                    label="Token"
                    message={intl.formatMessage({ id: "tokenModal_confirm" })}
                    value={form.token}
                    onChange={(event: any) => {
                      setForm({ ...form, token: event.target.value });
                    }}
                  />

                  <Row className="token-form-row">
                    <Col xs={3} md={3}>
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="button"
                        onClick={handleFormToken}
                      >
                        {intl.formatMessage({ id: "tokenModal_end" })}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    )
  );
}

export default TokenModal;
