import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import { translations } from "../translations";

export type TLanguages = "pt" | "es";

type TTranslateContext = {
  locale: TLanguages;
  setLanguage: (data: TLanguages) => void;
};

export const TranslateContext = createContext<TTranslateContext>({
  locale: "pt",
  setLanguage: () => {},
});

function TranslateContextProvider({ children }: PropsWithChildren) {
  const [locale, setLocale] = useState<TLanguages>("pt");

  function setLanguage(data: TLanguages) {
    setLocale(data);
    localStorage.setItem("@language", JSON.stringify(data));
  }

  useEffect(() => {
    const storage = localStorage.getItem("@language");
    const item = storage ? JSON.parse(storage) : "pt";
    setLocale(item);
  }, [locale]);

  return (
    <TranslateContext.Provider value={{ locale, setLanguage }}>
      <IntlProvider
        messages={translations[locale]}
        locale={locale}
        defaultLocale={"pt"}
      >
        {children}
      </IntlProvider>
    </TranslateContext.Provider>
  );
}

export const useTranslate = () => useContext(TranslateContext);

export default TranslateContextProvider;
