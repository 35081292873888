import axios from "axios";

const api = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASEPATH_API,
  });

  return axiosInstance;
};

export default api;
