export const bussinessModels = [
  {
    label: "B2B",
    value: "B2B",
  },
  {
    label: "B2C",
    value: "B2C",
  },
  {
    label: "B2G",
    value: "B2G",
  },
  {
    label: "B2B2C",
    value: "B2B2C",
  },
  {
    label: "B2S",
    value: "B2S",
  },
];
