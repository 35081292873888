import "./styles.scss";

const Input = ({
  type,
  name,
  label,
  message,
  value,
  onChange,
  hasError,
}: any) => {
  return (
    <div className="field">
      <input type={type} name={name} value={value} onChange={onChange} />
      <label htmlFor="email" className={`${value ? "labelValueOn" : ""}`}>
        {label}
      </label>
      <p className={hasError ? "input-message error" : "input-message"}>
        {message}
      </p>
    </div>
  );
};

export default Input;
