import { useIntl } from "react-intl";
import Column from "../../../../components/Column";
import Row from "../../../../components/Row";
import { RedirectProps } from "../../../../types/types";
import "./styles.scss";

function Companies({ innerRef }: RedirectProps) {
  const intl = useIntl();
  return (
    <div className="companies" ref={innerRef}>
      <h1>{intl.formatMessage({ id: "enterprises_title" })}</h1>
      <div className="companies-card">
        <Row>
          <Column>
            <div className="companies-card-item">
              <img
                src="../images/Logos/thyssenkrupp.png"
                alt="Thyssenkrupp"
                draggable={false}
              />
            </div>
          </Column>
          <Column>
            <div className="companies-card-item">
              <img
                src="../images/Logos/auxiliadora.png"
                alt="Auxiliadora Predial"
                draggable={false}
              />
            </div>
          </Column>
          <Column>
            <div className="companies-card-item">
              <img
                src="../images/Logos/helice.png"
                alt="Hélice"
                draggable={false}
              />
            </div>
          </Column>
          <Column>
            <div className="companies-card-item start-health">
              <img
                src="../images/Logos/starthealth.png"
                alt="Start Health"
                draggable={false}
              />
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
}

export default Companies;
