import "./styles.scss";
import { HiX } from "react-icons/hi";
import { FaRegCheckCircle, FaRegClock, FaRegTimesCircle } from "react-icons/fa";

type Props = {
  title: string;
  text: string;
  show: boolean;
  kind: string;
  onClick?: () => void;
};

export const Alert = ({ title, text, show, kind, onClick }: Props) => {
  return (
    <div>
      {show && (
        <div className={`alertComponent ${kind}`}>
          {kind === "success" && <FaRegCheckCircle size={24} style={{ marginTop: '10px' }} />}
          {kind === "warning" && <FaRegClock size={24} style={{ marginTop: '10px' }} />}
          {kind === "error" && <FaRegTimesCircle size={24} style={{ marginTop: '10px' }} />}
          <div className="alertContent">
            <h4>{title}</h4>
            <p>{text}</p>
          </div>
          <HiX className="icon" size={18} onClick={onClick} />
        </div>
      )}
    </div>
  );
};
