import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from '../views/Home';
import CompanyChallenges from "../views/CompanyChallenges";
import StartupRegister from "../views/StartupRegister";
import ChallengesRegister from "../views/ChallengesRegister";

export const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/desafios/:slug" element={<CompanyChallenges />} />
                <Route path="/startupregister/:slug&:challengeID" element={<StartupRegister/>} />
                <Route path="/challengesregister/:slug&:challengeID&:startupID" element={<ChallengesRegister/>}/>

                {/* Para a próxima página basta importar o componente e adicionar uma rota no 'path' */}
                {/* <Route path="/exemple" element={<Exemple />} /> */}
            </Routes>
        </Router>
    );
}

export default AppRoutes;
