import './styles.scss';
import { RowProps } from '../../types/types';

export default function Row({ children, centered = false, padding = '1rem 0px', height, align = false }: RowProps) {
  return (
    <div
      style={{ justifyContent: centered ? 'center' : 'start', padding, height, alignItems: align ? 'center' : 'start' }}
      className="container-row"
    >
      {children}
    </div>
  );
}
