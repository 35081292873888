import { useState, useEffect } from 'react';
import NavBar from '../../../../components/Navbar';

export default function Header({ homeSection, aboutSection, challengesSection, companiesSection, contactSection }: any){

    const [gotoHome, setGotoHome] = useState(false);
    const [gotoAbout, setGotoAbout] = useState(false);
    const [gotoChallenges, setGotoChallenges] = useState(false);
    const [gotoCompanies, setGotoCompanies] = useState(false);
    const [gotoContact, setGotoContact] = useState(false);

    useEffect(() => {
        if (gotoHome && homeSection.current) {
            homeSection.current.scrollIntoView({ behavior: "smooth" });
            setGotoHome(false);
        } else if (gotoAbout && aboutSection.current) {
            aboutSection.current.scrollIntoView({ behavior: "smooth" });
            setGotoAbout(false);
        } else if (gotoChallenges && challengesSection.current) {
            challengesSection.current.scrollIntoView({ behavior: "smooth" });
            setGotoChallenges(false);
        } else if (gotoCompanies && companiesSection.current) {
            companiesSection.current.scrollIntoView({ behavior: "smooth" });
            setGotoCompanies(false);
        } else if (gotoContact && contactSection.current) {
            contactSection.current.scrollIntoView({ behavior: "smooth" });
            setGotoContact(false);
        }
    }, [gotoHome, homeSection, gotoAbout, setGotoAbout, gotoChallenges, setGotoChallenges, gotoCompanies, setGotoCompanies, gotoContact, setGotoContact, aboutSection, challengesSection, companiesSection, contactSection])

    return(
        <NavBar
        onClickHome={() => setGotoHome(true)}
        onClickAbout={() => setGotoAbout(true)}
        onClickChallenges={() => setGotoChallenges(true)}
        onClickCompanies={() => setGotoCompanies(true)}
        onClickContact={() => setGotoContact(true)}
    />
    )
}
