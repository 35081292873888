import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import "./styles.scss";

function ClosedChallengesCard({
  banner,
  logo,
  title,
  subtitle,
  description,
  slug,
}: any) {
  const intl = useIntl();
  return (
    <div className="closedchallenges-card">
      <div className="closedchallenges-card-item">
        <img
          src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${banner}`}
          alt="Card Icon"
          draggable={false}
        />
        <div className="logo-circle">
          <img
            src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${logo}`}
            alt="Card Logo"
            draggable={false}
          />
        </div>
        <div className="closedchallenges-card-body">
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
          <p className="description">{description}</p>
        </div>
        <Link className="challenges-link" to={`/desafios/${slug}`}>
          <div className="closedchallenges-card-link">
            <p>{intl.formatMessage({ id: "knowMore" })}</p>
            <img
              src="../images/Icons/right-arrow-icon.svg"
              alt="Card Icon"
              draggable={false}
            />
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ClosedChallengesCard;
