import { FormattedMessage } from "react-intl";
import "./styles.scss";

function CompanyFooter({ color_primary, logo }: any) {
  const date = new Date();
  return (
    <div className="company-footer" style={{ background: color_primary }}>
      <div className="back-home-link">
        <a href="/" role="button">
          <img
            src="../images/Icons/arrow-left.svg"
            alt="Back Icon"
            draggable={false}
          />
          {<FormattedMessage id="comeBack" />}
        </a>
      </div>
      <div className="footer-logo">
        <div className="footer-background">
          <img
            src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${logo}`}
            alt="Logo Empresa"
            draggable={false}
          />
        </div>
      </div>
      <div className="company-footer-description">
        <p>
          <FormattedMessage
            id="developBy"
            values={{ date: date.getFullYear() }}
          />{" "}
          <a href="https://startaideia.com.br/">Startaideia</a>{" "}
        </p>
      </div>
    </div>
  );
}

export default CompanyFooter;
