import { useIntl } from "react-intl";
import { RedirectProps } from "../../types/types";
import "./styles.scss";

function Banner({ innerRef }: RedirectProps) {
  const intl = useIntl();
  return (
    <div className="banner" ref={innerRef}>
      <div className="desafios-banner text-center font-family">
        <div className="square">
          <img src="../images/Banner/Grupo9.png" alt="Open Plataforma" />
        </div>
        <div className="text-label">
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: "banner_title" }),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
