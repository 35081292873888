import './styles.scss';

function AboutCompany({ primary_color, description_title, site_description }: any) {

    return (
        <div className="about-company">
            <h1 style={{ color: primary_color }}>
                {description_title}
            </h1>
            <p dangerouslySetInnerHTML={{__html: site_description}}></p>
        </div>
    )
}

export default AboutCompany;