import './styles.scss';
import Banner from '../../components/Banner'
import StartupForm from './components/StartupForm';
import Footer from '../../components/Footer';

function StartupRegister() {
    return (
        <div className="startup-register">
            <Banner />
            <StartupForm/>
            <Footer backHome={true}/>
        </div>
    )
}

export default StartupRegister;