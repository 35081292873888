import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ClosedChallengesCard from "../../../../components/ClosedChallengesCard";
import Column from "../../../../components/Column";
import Row from "../../../../components/Row";
import api from "../../../../services/api";
import "./styles.scss";

function ClosedChallenges() {
  const [ClosedChallenges, setClosedChallenges] = useState<any>([]);
  const intl = useIntl();

  useEffect(() => {
    api()
      .get("/closed-challenges")
      .then((response) => {
        setClosedChallenges(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="closed-challenges">
      <h1>{intl.formatMessage({ id: "closedChallenges_title" })}</h1>
      <div className="inner">
        <Row>
          {ClosedChallenges.map((element: any, index: number) => {
            return (
              <Column key={index}>
                <ClosedChallengesCard
                  banner={element.banner}
                  logo={element.logo}
                  title={element.title}
                  subtitle={element.subtitle}
                  description={element.description}
                  slug={element.slug}
                />
              </Column>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default ClosedChallenges;
