import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "../../../../components/Alert";
import Button from "../../../../components/Button";
import { emailRegex } from "../../../../constants/regex";
import { useTranslate } from "../../../../contexts/TranslateContext";
import api from "../../../../services/api";
import { maskPhone } from "../../../../utils/mask";
import "./styles.scss";

export type Props = {
  name: string;
  email: string;
  phone: string;
  gender: string;
  dateBirth: string;
  city: string;
  nameStartup: string;
  siteStartup: string;
  linkedin: string;
};

export type AlertProps = {
  title: string;
  text: string;
  show: boolean;
  kind: string;
};

function StartupForm() {
  const navigate = useNavigate();
  const { locale } = useTranslate();
  const [states, setStates] = useState<any>();
  const [cities, setCities] = useState<any>();
  const { slug } = useParams<any>();
  const { challengeID } = useParams<any>();

  const [hasError, setHasError] = useState<boolean>(false);

  const [form, setForm] = useState<Props>({
    name: "",
    email: "",
    phone: "",
    gender: "",
    dateBirth: "",
    city: "",
    nameStartup: "",
    siteStartup: "",
    linkedin: "",
  });

  const [alert, setAlert] = useState<AlertProps>({
    title: "",
    text: "",
    show: false,
    kind: "",
  });

  function getStates() {
    api()
      .get("/states")
      .then((response: any) => {
        setStates(response.data.states);
      })
      .catch((response: any) => {
        console.error(response.data);
      });
  }

  function getCities(id: any) {
    api()
      .get(`/cities/${id}`)
      .then((response: any) => {
        setCities(response.data.cities);
      })
      .catch((response: any) => {
        console.error(response.data);
      });
  }

  useEffect(() => {
    getStates();
  }, []);

  function handleAlert() {
    setAlert({ ...alert, show: !alert.show });
  }

  function handleForm(event: any) {
    event.preventDefault();
    if (!isEmailValid) {
      setHasError(true);
      return;
    }

    var bodyFormData: any = new FormData();
    bodyFormData.append("nameRepresentative", form.name);
    bodyFormData.append("emailRepresentative", form.email);
    bodyFormData.append("phoneRepresentative", form.phone);
    bodyFormData.append("genderRepresentative", form.gender);
    bodyFormData.append("dateBirthRepresentativeString", form.dateBirth);
    bodyFormData.append(
      "cityRepresentative",
      locale === "pt" ? form.city : 5571
    );
    bodyFormData.append("nameStartup", form.nameStartup);
    bodyFormData.append("siteStartup", form.siteStartup);
    bodyFormData.append("challenge_id", challengeID);

    if (form.linkedin) bodyFormData.append("linkedin", form.linkedin);

    api()
      .post(`/startup/create`, bodyFormData)
      .then((response: any) => {
        setAlert({
          ...alert,
          title: "Sucesso",
          text: "Formulário enviado com sucesso!",
          kind: "success",
          show: true,
        });
        navigate(
          `/challengesregister/${slug}&${challengeID}&${response.data.startup.id}`
        );
      })
      .catch((response: any) => {
        console.error(response);
        setAlert({
          ...alert,
          title: "Erro",
          text: response.data,
          kind: "error",
          show: true,
        });
      });
  }

  const isEmailValid = emailRegex.test(form.email);

  const intl = useIntl();

  return (
    <div className="startup-form">
      <Alert
        title={alert.title}
        text={alert.text}
        show={alert.show}
        kind={alert.kind}
        onClick={handleAlert}
      />
      <h1>{intl.formatMessage({ id: "startupForm_title" })}</h1>
      <div className="container">
        <Form onSubmit={handleForm}>
          <Row className="mb-3">
            <Form.Group controlId="formGridName" as={Col}>
              <Form.Label>
                {intl.formatMessage({ id: "startupForm_name" })}
              </Form.Label>
              <Form.Control
                placeholder=""
                onChange={(event: any) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>
                {intl.formatMessage({ id: "startupForm_email" })}
              </Form.Label>
              <Form.Control
                placeholder="seuemail@email.com"
                onChange={(event: any) => {
                  if (isEmailValid) {
                    setHasError(false);
                  }
                  setForm({ ...form, email: event.target.value });
                }}
              />
              {hasError && (
                <p className="error">
                  {intl.formatMessage({ id: "startupForm_emailError" })}
                </p>
              )}
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group controlId="formGridLinkedin" as={Col}>
              <Form.Label>Linkedin</Form.Label>
              <Form.Control
                placeholder=""
                onChange={(event: any) =>
                  setForm({ ...form, linkedin: event.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPhone">
              <Form.Label>
                {intl.formatMessage({ id: "startupForm_phone" })}
              </Form.Label>
              <Form.Control
                value={maskPhone(form.phone)}
                maxLength={15}
                type="phone"
                placeholder="(DDD) 12345-6789"
                onChange={(event: any) =>
                  setForm({ ...form, phone: event.target.value })
                }
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridGender">
              <Form.Label>
                {intl.formatMessage({
                  id: "startupForm_gender",
                })}
              </Form.Label>
              <Form.Select
                defaultValue={intl.formatMessage({
                  id: "startupForm_gender_placeholder",
                })}
                onChange={(event: any) =>
                  setForm({ ...form, gender: event.target.value })
                }
              >
                <option>
                  {intl.formatMessage({
                    id: "startupForm_gender_placeholder",
                  })}
                </option>
                <option value="F">
                  {intl.formatMessage({
                    id: "startupForm_gender_female",
                  })}
                </option>
                <option value="M">
                  {intl.formatMessage({
                    id: "startupForm_gender_masculine",
                  })}
                </option>
                <option value="O">
                  {intl.formatMessage({
                    id: "startupForm_gender_other",
                  })}
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridBirth">
              <Form.Label>
                {intl.formatMessage({
                  id: "startupForm_birthdate",
                })}
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="DD/MM/AAAA"
                onChange={(event: any) =>
                  setForm({ ...form, dateBirth: event.target.value })
                }
              />
            </Form.Group>
          </Row>
          {locale === "pt" && (
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>
                  {intl.formatMessage({
                    id: "startupForm_state",
                  })}
                </Form.Label>
                <Form.Select
                  onChange={(event: any) => {
                    getCities(event.target.value);
                  }}
                >
                  <option>---</option>
                  {states &&
                    states.map((state: any, index: number) => {
                      return (
                        <option value={state.id} key={index}>
                          {state.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>
                  {intl.formatMessage({
                    id: "startupForm_city",
                  })}
                </Form.Label>
                <Form.Select
                  defaultValue="---"
                  onChange={(event: any) =>
                    setForm({ ...form, city: event.target.value })
                  }
                >
                  <option>---</option>
                  {cities &&
                    cities.map((city: any, index: number) => {
                      return (
                        <option value={city.id} key={index}>
                          {city.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Row>
          )}
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridStartupName">
              <Form.Label>
                {intl.formatMessage({
                  id: "startupForm_startupName",
                })}
              </Form.Label>
              <Form.Control
                placeholder=""
                onChange={(event: any) =>
                  setForm({ ...form, nameStartup: event.target.value })
                }
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridSite">
              <Form.Label>
                {intl.formatMessage({
                  id: "startupForm_startupSite",
                })}
              </Form.Label>
              <Form.Control
                type="phone"
                placeholder="https://meusite.com.br"
                onChange={(event: any) =>
                  setForm({ ...form, siteStartup: event.target.value })
                }
              />
            </Form.Group>
          </Row>

          <div className="button-div">
            <Button
              text={intl.formatMessage({
                id: "signUp",
              })}
              backgroundColor="#3D0060"
              color="#fff"
              uppercase
            />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default StartupForm;
