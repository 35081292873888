import { useIntl } from "react-intl";
import Column from "../../../../components/Column";
import Row from "../../../../components/Row";
import "./styles.scss";

function HowItWorks() {
  const intl = useIntl();
  return (
    <div className="how-it-works">
      <h1>{intl.formatMessage({ id: "howItWorks_title" })}</h1>
      <Row>
        <Column>
          <div className="card">
            <div className="card-item">
              <img
                src="../images/Icons/magic-cube-icon.svg"
                alt="Card Icon"
                draggable={false}
              />
              <p className="card-title">
                {intl.formatMessage({ id: "howItWorks_challenges_title" })}
              </p>
              <p>
                {intl.formatMessage({
                  id: "howItWorks_challenges_description",
                })}
              </p>
            </div>
          </div>
        </Column>
        <Column>
          <div className="card">
            <div className="card-item">
              <img
                src="../images/Icons/lamp-icon.svg"
                alt="Card Icon"
                draggable={false}
              />
              <p className="card-title">
                {intl.formatMessage({ id: "howItWorks_idea_title" })}
              </p>
              <p>{intl.formatMessage({ id: "howItWorks_idea_description" })}</p>
            </div>
          </div>
        </Column>
        <Column>
          <div className="card">
            <div className="card-item">
              <img
                src="../images/Icons/rocket-icon.svg"
                alt="Card Icon"
                draggable={false}
              />
              <p className="card-title">
                {intl.formatMessage({ id: "howItWorks_connections_title" })}
              </p>
              <p>
                {intl.formatMessage({
                  id: "howItWorks_connections_description",
                })}
              </p>
            </div>
          </div>
        </Column>
      </Row>
    </div>
  );
}

export default HowItWorks;
