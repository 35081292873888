import { Fragment, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Carousel } from "../../components/Carousel";
import Column from "../../components/Column";
import api from "../../services/api";
import AboutCompany from "./components/AboutCompany";
import ChallengesBanner from "./components/ChallengesBanner";
import CompanyFooter from "./components/CompanyFooter";
import GeneralInformation from "./components/GeneralInformation";
import HackatonsCards from "./components/HackatonsCards";
import "./styles.scss";

function CompanyChallenges() {
  const { slug } = useParams();
  const [company, setCompany] = useState<any>([]);
  const [challenges, setChallenges] = useState<any>([]);
  const challengesSection = useRef<HTMLDivElement>(null);
  const [gotoChallenges, setGotoChallenges] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (gotoChallenges && challengesSection.current) {
      challengesSection.current.scrollIntoView({ behavior: "smooth" });
      setGotoChallenges(false);
    }
  }, [gotoChallenges, setGotoChallenges, challengesSection]);

  async function getCompany() {
    await api()
      .post(`/company?slug=${slug}`)
      .then((response: any) => {
        setCompany(response.data);
        getChallenges(response.data);
      })
      .catch((error: any) => {
        console.error(error.msg);
      });
  }

  async function getChallenges(data: any) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data[0].id);

    await api()
      .post(`/company-challenges?slug=${slug}`, bodyFormData)
      .then((response: any) => {
        setChallenges(response.data);
      })
      .catch((error: any) => {
        console.error(error.msg);
      });
  }

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div className="company-challenges">
      {company.map((element: any) => {
        return (
          <Fragment key={element.id}>
            <ChallengesBanner
              banner={company[0].banner}
              name={element.title}
              subtitle={element.subtitle}
              color_secundary={company[0].color_secundary}
              logo={element.logo}
              onclick={() => setGotoChallenges(true)}
            />

            <AboutCompany
              primaryColor={element.primary_color}
              description_title={element.description_title}
              site_description={element.site_description}
            />

            <h1 className="open-hackatons-title" ref={challengesSection}>
              {intl.formatMessage({ id: "challenges" })}
            </h1>
            <div style={{ width: "100vw" }}>
              <Carousel>
                {challenges.map((element: any, index: number) => {
                  return (
                    <Column key={index}>
                      <HackatonsCards
                        banner={company[0].banner}
                        title={element.title}
                        description={element.description}
                        color_secundary={company[0].color_secundary}
                        challengeid={element.id}
                      ></HackatonsCards>
                    </Column>
                  );
                })}
              </Carousel>
            </div>

            <GeneralInformation
              who_participates={element.who_participates}
              benefits={element.benefits}
            />

            <CompanyFooter
              color_primary={element.color_primary}
              logo={element.logo}
            />
          </Fragment>
        );
      })}
    </div>
  );
}

export default CompanyChallenges;
