import { useState } from "react";
import { Accordion, FloatingLabel, Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { bussinessModels } from "../../constants/bussinessModels";
import { capturingPhase } from "../../constants/capturingPhase";
import { segments } from "../../constants/segments";
import { useTranslate } from "../../contexts/TranslateContext";
import api from "../../services/api";
import { Alert } from "../Alert";
import Button from "../Button";
import "./styles.scss";

export type Props = {
  description: string;
  maturity: string;
  pitch: string;
  video: string;
  bussinessModel?: string;
  segment?: string;
  file?: File;
  capturingInvestments?: string;
  capturingPhase?: string;
  valuation?: string;
  invoicing?: string;
  growth?: string;
};

export type AlertProps = {
  title: string;
  text: string;
  show: boolean;
  kind: string;
};

export const Dropdown = ({
  startup,
  challenge,
  title,
  open = "1",
  onChange,
  maturities,
}: any) => {
  const { slug } = useParams<any>();
  const [alert, setAlert] = useState<AlertProps>({
    title: "",
    text: "",
    show: false,
    kind: "",
  });
  const [form, setForm] = useState<Props>({
    description: "",
    maturity: "",
    pitch: "",
    video: "",
  });
  const [loading, setLoading] = useState(false);

  const intl = useIntl();
  const { locale } = useTranslate();

  function handleForm(event: any) {
    event.preventDefault();
    if (!loading) {
      setLoading(true);
      var bodyFormData: any = new FormData();
      bodyFormData.append("startup_id", startup);
      bodyFormData.append("challenge_id", challenge);
      bodyFormData.append("maturityStartup", form.maturity);
      bodyFormData.append("descriptionStartup", form.description);
      bodyFormData.append("linkPitchStartup", form.pitch);
      bodyFormData.append("linkVideoStartup", form.video);

      bodyFormData.append("bussinessModel", form.bussinessModel);
      bodyFormData.append("segment", form.segment);
      bodyFormData.append("file", form.file);

      bodyFormData.append("capturingInvestments", form.capturingInvestments);
      bodyFormData.append("capturingPhase", form.capturingPhase);
      bodyFormData.append("valuation", form.valuation);
      bodyFormData.append("invoicing", form.invoicing);
      bodyFormData.append("growth", form.growth);

      api()
        .post(`/startup/challenge?${slug}`, bodyFormData)
        .then((response: any) => {
          setAlert({
            ...alert,
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "successMessage" }),
            kind: "success",
            show: true,
          });
          setLoading(false);
          event.target.reset();
        })
        .catch((response: any) => {
          console.error(response.data);
          setAlert({
            ...alert,
            title: intl.formatMessage({ id: "error" }),
            text: response.data,
            kind: "error",
            show: true,
          });
          setLoading(false);
        });
    }
  }

  function handleAlert() {
    setAlert({ ...alert, show: !alert.show });
  }

  return (
    <form className="container" onSubmit={handleForm}>
      <Alert
        title={alert.title}
        text={alert.text}
        show={alert.show}
        kind={alert.kind}
        onClick={handleAlert}
      />
      <Accordion defaultActiveKey={open}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{title}</Accordion.Header>
          <Accordion.Body>
            <p>{intl.formatMessage({ id: "dropdown_text" })}</p>
            <FloatingLabel
              controlId="floatingSolutionDescription"
              label={
                intl.formatMessage({
                  id: "dropdown_howCanYourProjectHelp_label",
                }) + "*"
              }
            >
              <Form.Control
                as="textarea"
                placeholder={
                  intl.formatMessage({
                    id: "dropdown_howCanYourProjectHelp_label",
                  }) + "*"
                }
                style={{ maxHeight: "200px", minHeight: "100px" }}
                onChange={(event: any) =>
                  setForm({ ...form, description: event.target.value })
                }
                required
              />
              <Form.Text className="text-muted" style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "dropdown_howCanYourProjectHelp_label",
                })}
              </Form.Text>
            </FloatingLabel>
            <br />
            <br />
            <Form.Label htmlFor="businessModel">
              {intl.formatMessage({ id: "dropdown_bussinessModel_label" })}
            </Form.Label>
            <Form.Select
              aria-label="businessModel"
              onChange={(event) =>
                setForm({ ...form, bussinessModel: event.target.value })
              }
            >
              <option>---</option>
              {bussinessModels.map((bussinessModel, index) => {
                return (
                  <option value={bussinessModel.value} key={index}>
                    {bussinessModel.label}
                  </option>
                );
              })}
            </Form.Select>
            <br />
            <Form.Label htmlFor="segment">
              {intl.formatMessage({ id: "dropdown_segment_label" })}
            </Form.Label>
            <Form.Select
              aria-label="segment"
              onChange={(event) =>
                setForm({ ...form, segment: event.target.value })
              }
            >
              <option>---</option>
              {segments.map((segment, index) => {
                return (
                  <option value={segment.value} key={index}>
                    {segment.label}
                  </option>
                );
              })}
            </Form.Select>
            <br />
            <Form.Label htmlFor="file">
              {intl.formatMessage({ id: "dropdown_logo_label" })}
            </Form.Label>
            <Form.Control
              type="file"
              id="file"
              onChange={(event: any) => {
                const file = event.target.files[0];
                setForm({ ...form, file: file });
              }}
            />

            <br />
            <Form.Label htmlFor="inputMaturity">
              {intl.formatMessage({ id: "dropdown_maturity_label" })}*
            </Form.Label>
            <Form.Select
              aria-label="maturity"
              onChange={(event: any) =>
                setForm({ ...form, maturity: event.target.value })
              }
              required
            >
              <option>---</option>
              {maturities &&
                maturities.map((maturitie: any, index: number) => {
                  return (
                    <option value={maturitie.id} key={index}>
                      {maturitie.value}
                    </option>
                  );
                })}
            </Form.Select>

            <br />
            <br />
            <FloatingLabel
              controlId="floatingPresentation"
              label={`${intl.formatMessage({
                id: "dropdown_presentation_label",
              })}*`}
            >
              <Form.Control
                type="text"
                placeholder={`${intl.formatMessage({
                  id: "dropdown_presentation_label",
                })}*`}
                onChange={(event: any) =>
                  setForm({ ...form, pitch: event.target.value })
                }
                required
              />
              <Form.Text className="text-muted" style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "dropdown_presentation_helper",
                })}
                <b>
                  {intl.formatMessage({
                    id: "dropdown_presentation_helper_bold",
                  })}
                </b>
              </Form.Text>
            </FloatingLabel>
            <br />
            <br />
            <FloatingLabel
              controlId="floatingVideo"
              label={`${intl.formatMessage({
                id: "dropdown_video_label",
              })}*`}
            >
              <Form.Control
                type="text"
                placeholder={`${intl.formatMessage({
                  id: "dropdown_video_label",
                })}*`}
                onChange={(event: any) =>
                  setForm({ ...form, video: event.target.value })
                }
                required
              />
              <Form.Text className="text-muted" style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "dropdown_video_helper",
                })}{" "}
                <b>
                  {intl.formatMessage({
                    id: "dropdown_presentation_helper_bold",
                  })}
                </b>
              </Form.Text>
            </FloatingLabel>
            <br />
            <br />

            <Form.Label htmlFor="capturingInvestments">
              {intl.formatMessage({
                id: "dropdown_capturingInvestments_label",
              })}
            </Form.Label>
            <Form.Select
              aria-label="capturingInvestments"
              onChange={(event: any) =>
                setForm({ ...form, capturingInvestments: event.target.value })
              }
            >
              <option>---</option>
              <option>
                {intl.formatMessage({
                  id: "yes",
                })}
              </option>
              <option>
                {intl.formatMessage({
                  id: "no",
                })}
              </option>
            </Form.Select>
            <br />
            <Form.Label htmlFor="capturingPhase">
              {intl.formatMessage({
                id: "dropdown_capturingPhase_label",
              })}
            </Form.Label>
            <Form.Select
              aria-label="capturingPhase"
              onChange={(event: any) =>
                setForm({ ...form, capturingPhase: event.target.value })
              }
            >
              <option>---</option>
              {capturingPhase[locale].map((phase, index) => {
                return (
                  <option value={phase.value} key={index}>
                    {phase.label}
                  </option>
                );
              })}
            </Form.Select>
            <br />
            <Form.Label htmlFor="valuation">Valuation</Form.Label>

            <Form.Control
              type="text"
              onChange={(event: any) =>
                setForm({ ...form, valuation: event.target.value })
              }
            />

            <br />
            <Form.Label htmlFor="invoicing">
              {intl.formatMessage({
                id: "dropdown_invoicing_label",
              })}
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(event: any) =>
                setForm({ ...form, invoicing: event.target.value })
              }
            />
            <br />
            <Form.Label htmlFor="growth">
              {intl.formatMessage({
                id: "dropdown_growth_label",
              })}
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(event: any) =>
                setForm({ ...form, growth: event.target.value })
              }
            />

            <div className="dropdown-button">
              <Button
                type="submit"
                text={intl.formatMessage({ id: "save" })}
                backgroundColor="#3D0060"
                color="#fff"
                loading={loading}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </form>
  );
};
