import { Spinner } from "react-bootstrap";
import { PrimaryButtonProps } from "../../types/types";
import "./styles.scss";

const Button = ({
  width = "109px",
  height = "50px",
  borderRadius = "5px",
  backgroundColor = "#ffffff",
  color = "#28003f",
  fontWeight = "600",
  fontSize = "16px",
  text,
  marginBottom,
  onClick,
  uppercase,
  loading = false,
}: PrimaryButtonProps) => {
  return (
    <button
      type="submit"
      style={{
        width,
        height,
        backgroundColor: loading ? "#bda3ce" : backgroundColor,
        fontWeight,
        fontSize,
        color: loading ?  "#3D0060" : color,
        marginBottom,
        borderRadius,
        textTransform: uppercase ? "uppercase" : "none",
      }}
      onClick={onClick}
    >
      {loading && (
        <Spinner animation="border" size="sm" role="status"></Spinner>
      )}
      {text}
    </button>
  );
};

export default Button;
