import axios from "axios";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Alert } from "../../../../components/Alert";
import Button from "../../../../components/Button";
import Column from "../../../../components/Column";
import Row from "../../../../components/Row";
import { RedirectProps } from "../../../../types/types";
import { maskPhone } from "../../../../utils/mask";
import "./styles.scss";

function Contact({ innerRef }: RedirectProps) {
  const [alertSuccess, setSAlertSuccess] = useState(false);
  const [alertError, setSAlertError] = useState(false);

  const [form, setForm] = useState<any>({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  function handleSubmit() {
    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", {
        user_id: "ajLx8-fKleO9MfqB5",
        service_id: "service_u0fehjr",
        template_id: "template_k47f34s",
        template_params: {
          contact_name: form.name,
          contact_email: form.email,
          contact_phone: maskPhone(form.phone),
          contact_message: form.message,
        },
        accessToken: "h_490cNA4B7GFcXgpBL8W",
      })
      .then((response: any) => {
        setSAlertSuccess(true);
      })
      .catch((err: any) => {
        console.error("ops! ocorreu um erro" + err);
        setSAlertError(true);
      });
  }

  const intl = useIntl();

  return (
    <div className="contact" ref={innerRef}>
      <Alert
        title="Enviado com sucesso!"
        text="Seus dados para contato foram enviados para o nosso time interno."
        kind="success"
        show={false}
        onClick={() => setSAlertSuccess(false)}
      />
      <Alert
        title="Erro!"
        text="Infelizmente houve algum erro com seu envio. Por favor, tente novamente"
        kind="error"
        show={true}
        onClick={() => setSAlertError(false)}
      />
      <h1 className="contact-title">
        {intl.formatMessage({ id: "contact_title" })}
      </h1>
      <div className="contact-area">
        <Row>
          <div className="contact-text">
            <h1>{intl.formatMessage({ id: "contact_subtitle" })}</h1>
            <p>{intl.formatMessage({ id: "contact_description" })}</p>
          </div>
          <Column>
            <div className="input-div">
              <label>{intl.formatMessage({ id: "contact_name" })} *</label>
              <input
                type="text"
                placeholder={intl.formatMessage({ id: "contact_name" })}
                onChange={(event: any) =>
                  setForm({ ...form, name: event.target.value })
                }
              />
            </div>

            <div className="input-div">
              <label>{intl.formatMessage({ id: "contact_email" })} *</label>
              <input
                type="email"
                onChange={(event: any) =>
                  setForm({ ...form, email: event.target.value })
                }
                placeholder={intl.formatMessage({ id: "contact_email" })}
              />
            </div>
            <div className="input-div">
              <label>{intl.formatMessage({ id: "contact_phone" })} *</label>
              <input
                type="phone"
                value={maskPhone(form.phone)}
                maxLength={15}
                onChange={(event: any) =>
                  setForm({ ...form, phone: event.target.value })
                }
                placeholder={intl.formatMessage({ id: "contact_phone" })}
              />
            </div>
            <div className="input-div">
              <label>{intl.formatMessage({ id: "contact_message" })} *</label>
              <textarea
                onChange={(event: any) =>
                  setForm({ ...form, message: event.target.value })
                }
                placeholder=""
              />
            </div>

            <div className="button-div">
              <Button
                text={intl.formatMessage({ id: "contact_button" })}
                onClick={handleSubmit}
                uppercase
              />
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
}

export default Contact;
