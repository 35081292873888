import { useIntl } from "react-intl";
import Column from "../../../../components/Column";
import { RedirectProps } from "../../../../types/types";
import "./styles.scss";

function About({ innerRef }: RedirectProps) {
  const intl = useIntl();
  return (
    <div className="about-area" ref={innerRef}>
      <Column>
        <div className="about-text">
          <h1>{intl.formatMessage({ id: "about_title" })}</h1>
          <p>{intl.formatMessage({ id: "about_description" })}</p>
        </div>
      </Column>
      <Column>
        <img
          src="../images/about-img-rocket.png"
          alt="O Open Plataforma"
          draggable={false}
        />
      </Column>
    </div>
  );
}

export default About;
