export const portuguese = {
  navbar_home: "Home",
  navbar_about: "A Open Plataforma",
  navbar_challenges: "Desafios e Hackatons",
  navbar_enterprises: "Empresas",
  navbar_contact: "Contato",

  banner_title:
    " Aqui grandes <br />  ideias são sempre <br />  bem vindas<span>_</span>",

  about_title: "Transformando potencial inovador em resultados",
  about_description:
    "A Open é uma Plataforma de desafios corporativos e inovação aberta. Aproximamos empresas e startups em buscas de soluções inovadoras para o mercado, a partir da organização de Desafios e Hackathons.",

  howItWorks_title: "Como funciona?",
  howItWorks_challenges_title: "Acesse os Desafios e Hackatons",
  howItWorks_challenges_description:
    "Acesse os Desafios e hackathons abertos na Open Plataforma.",
  howItWorks_idea_title: "Inscreva a sua ideia",
  howItWorks_idea_description:
    "Tem uma ideia inovadora para um ou mais desafios? Inscreva a sua Startup e a solução que ela propõe.",
  howItWorks_connections_title: "Crie conexões",
  howItWorks_connections_description:
    "Tenha acesso a potenciais clientes que poderão comprar e contribuir com o desenvolvimento de suas soluções.",

  openChallenges_title: "Desafios e hackathons abertos",
  closedChallenges_title: "Desafios e hackathons encerrados",
  enterprises_title: "Empresas que fazem parte deste movimento",

  contact_title: "Entre em contato",
  contact_subtitle: "Promova um Desafio ou Hackaton com a Open!",
  contact_description:
    "Se você tem dúvidas ou deseja promover um Desafio ou Hackathon entre em contato conosco",
  contact_name: "Nome Completo",
  contact_email: "E-mail",
  contact_phone: "Telefone",
  contact_message: "Nos fale mais sobre a sua empresa ou projeto",
  contact_button: "Enviar",

  knowMore: "Saiba mais",
  seeChallenges: "Ver oportunidades",
  challenges: "Oportunidades",

  developBy: "Todos os direitos reservados - {date}. Desenvolvido por",
  comeBack: "Voltar para a página inicial",

  accordion_title: "Inscrever no Desafio",

  generalInformation: "Quem pode participar?",
  generalInformation_whoParticipate: "Quem pode participar?",
  generalInformation_benefits: "Benefícios",
  generalInformation_questions:
    "Tem alguma dúvida? Entre em contato com a gente: summit@iguassuvalley.com.br",
  generalInformation_notice: "Baixe aqui o Edital da oportunidade",
  generalInformation_notice_button: "Baixar",

  signUp: "Inscreva-se",

  tokenModal_title: "Olá, bem vindo(a)!",
  tokenModal_subtitle:
    "Para sua segurança, você receberá via e-mail um código de autenticação. Basta informar o código quando solicitado para prosseguir com sua inscrição no desafio.",
  tokenModal_input: "E-mail",
  tokenModal_inputMessage:
    "Digite um e-mail válido para receber o token de autenticação.",
  tokenModal_button: "Continuar",
  tokenModal_confirm: "Token de autenticação recebido via e-mail.",
  tokenModal_end: "Finalizar",

  startupForm_title: "Inscrever Startup",
  startupForm_name: "Nome",
  startupForm_email: "E-mail",
  startupForm_emailError: "E-mail inválido",
  startupForm_phone: "Telefone",
  startupForm_gender: "Gênero",
  startupForm_gender_female: "Feminino",
  startupForm_gender_masculine: "Masculino",
  startupForm_gender_other: "Outro",
  startupForm_gender_placeholder: "Escolha...",
  startupForm_birthdate: "Data de nascimento",
  startupForm_state: "Estado",
  startupForm_city: "Cidade",
  startupForm_startupName: "Nome da Startup",
  startupForm_startupSite: "Site da Startup",

  backHome: "Voltar para a página inicial",

  success: "Sucesso",
  successMessage: "Formulário enviado com sucesso!",
  error: "Erro",

  dropdown_text:
    "Identificar pontos de melhoria nos processos operacionais visando automatização e digitalização dos processos aumentando eficiência e reduzindo fraudes.",
  dropdown_howCanYourProjectHelp_label:
    "Como o seu projeto pode apoiar este desafio?",
  dropdown_howCanYourProjectHelp_helper:
    "Em até 1000 caracteres escreva problema que sua startup resolve e o seu modelo de negócios.",
  dropdown_bussinessModel_label: "Modelo de Negócios",
  dropdown_segment_label: "Segmento",
  dropdown_logo_label: "Logo da empresa",
  dropdown_maturity_label: "Maturidade",
  dropdown_presentation_label: "Apresentação",
  dropdown_presentation_helper:
    "Cole aqui o link de sua apresentação para download (Dropbox, Google Drive ou similares). / ",
  dropdown_presentation_helper_bold: "Vídeo no máximo 3min.",
  dropdown_video_label: "Vídeo",
  dropdown_video_helper:
    "Se você tiver um vídeo a respeito do seu negócio, cole o link aqui (não esqueça de torná-lo público)./",
  dropdown_capturingInvestments_label:
    "Está captando investimento ou pretende abrir rodada de captação em 2023?",
  dropdown_capturingPhase_label: "Estágio de Captação",
  dropdown_invoicing_label: "Faturamento médio dos últimos 12 meses",
  dropdown_growth_label: "% de crescimento médio dos últimos 12 meses",
  yes: "Sim",
  no: "Não",
  save: "Salvar",
};
