import { FormattedMessage, useIntl } from "react-intl";
import "./styles.scss";

function Footer({ backHome = false }: any) {
  const date = new Date();
  const intl = useIntl();

  return (
    <div className="footer">
      {backHome && (
        <div className="back-home-link">
          <a href="/" role="button">
            <img
              src="../images/Icons/arrow-left.svg"
              alt="Back Icon"
              draggable={false}
            />
            {intl.formatMessage({ id: "backHome" })}
          </a>
        </div>
      )}
      <div className="open-logo">
        <img
          src="../images/Logos/open-white-pink-logo.png"
          alt="Logo Open Plataforma"
          draggable={false}
        />
      </div>
      <div className="footer-description">
        <p>
          <FormattedMessage
            id="developBy"
            values={{ date: date.getFullYear() }}
          />{" "}
          <a href="https://startaideia.com.br/">Startaideia</a>{" "}
        </p>
      </div>
    </div>
  );
}

export default Footer;
