import { useState } from "react";
import { useIntl } from "react-intl";
import TokenModal from "../TokenModal";
import "./styles.scss";

function HackatonsCards({ banner, title, description, challengeid }: any) {
  const [modalShow, setModalShow] = useState(false);
  const intl = useIntl();

  return (
    <div className="hackatons-card">
      <div className="hackatons-card-item">
        <img
          src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${banner}`}
          alt="Card Icon"
          className="hackatons-banner"
          draggable={false}
        />
        <div className="hackatons-content">
          <div className="hackatons-card-body">
            <h1>{title}</h1>
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            ></p>
          </div>

          <button
            onClick={() => setModalShow(true)}
            className="hackatons-button"
          >
            {intl.formatMessage({
              id: "signUp",
            })}
          </button>
        </div>
        <TokenModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          challengeid={challengeid}
        />
      </div>
    </div>
  );
}

export default HackatonsCards;
