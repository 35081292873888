export const segments = [
  {
    value: 'agrotech',
    label: 'Agrotech'
  },
  {
    value: 'retailtech',
    label: 'Retailtech'
  },
  {
    value: 'energias',
    label: 'Energias'
  },
  {
    value: 'smartCities',
    label: 'Smart Cities'
  },
  {
    value: 'industria40',
    label: 'Indústria 4.0'
  },
  {
    value: 'edtech',
    label: 'Edtech'
  },
  {
    value: 'wellness',
    label: 'Wellness'
  },
  {
    value: 'fintech',
    label: 'Fintech'
  },
  {
    value: 'legaltech',
    label: 'Legaltech'
  }
]