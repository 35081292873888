import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import "./styles.scss";

function OpenChallengesCard({
  banner,
  logo,
  title,
  subtitle,
  description,
  slug,
}: any) {
  const intl = useIntl();
  return (
    <div className="openchallenges-card">
      <div className="openchallenges-card-item">
        <img
          src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${banner}`}
          alt="Card Icon"
          draggable={false}
        />
        <div className="logo-circle">
          <img
            src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${logo}`}
            alt="Card Logo"
            draggable={false}
          />
        </div>
        <div className="openchallenges-card-body">
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
          <p className="description">{description}</p>
        </div>
        <div className="openchallenges-card-button">
          <Link to={`/desafios/${slug}`}>
            <div className="openchallenges-button">
              <p>{intl.formatMessage({ id: "seeChallenges" })}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OpenChallengesCard;
