export const spanish = {
  navbar_home: "Inicio",
  navbar_about: "La plataforma abierta",
  navbar_challenges: "Desafíos y Hackathons",
  navbar_enterprises: "Empresas",
  navbar_contact: "Contacto",

  banner_title:
    " Aquí las grandes <br /> ideas siempre son <br /> bienvenidas<span>_</span>",

  about_title: "Transformar el potencial innovador en resultados",
  about_description:
    "Open es una Plataforma para desafíos corporativos e innovación abierta. Reunimos a empresas y startups en busca de soluciones innovadoras al mercado, a través de la organización de Challenges y Hackathons.",

  howItWorks_title: "¿Cómo funciona?",
  howItWorks_challenges_title: "Acceso a desafíos y hackatones",
  howItWorks_challenges_description:
    "Acceda a desafíos abiertos y hackatones en la plataforma abierta.",
  howItWorks_idea_title: "Envía tu idea",
  howItWorks_idea_description:
    "¿Tienes una idea innovadora para uno o más desafíos? Registra tu Startup y la solución que propone.",
  howItWorks_connections_title: "Crear conexiones",
  howItWorks_connections_description:
    "Obtenga acceso a clientes potenciales que podrán comprar y contribuir al desarrollo de sus soluciones.",

  openChallenges_title: "Desafíos abiertos y hackathons",
  closedChallenges_title: "Desafíos cerrados y hackathons",
  enterprises_title: "Empresas que forman parte de este movimiento",

  contact_title: "Ponte en contacto",
  contact_subtitle: "¡Promociona un desafío o hackathon con Open!",
  contact_description:
    "Si tienes dudas o quieres promocionar un Reto o Hackathon por favor contáctanos",
  contact_name: "Nombre completo",
  contact_email: "Correo electrónico",
  contact_phone: "Teléfono",
  contact_message: "Cuéntanos más sobre tu empresa o proyecto",
  contact_button: "Enviar",

  knowMore: "Saber más",
  seeChallenges: "Ver oportunidades",
  challenges: "Oportunidades",

  developBy: "Todos os direitos reservados - {date}. Desenvolvido por",
  comeBack: "Volver a la página de inicio",

  accordion_title: "Inscríbete en el desafío",

  generalInformation: "¿Quién puede participar?",
  generalInformation_whoParticipate: "¿Quién puede participar?",
  generalInformation_benefits: "Beneficios",
  generalInformation_questions:
    "¿Tiene alguna pregunta? Póngase en contacto con nosotros: summit@iguassuvalley.com.br",
  generalInformation_notice: "Descargue el aviso de oportunidad aquí",
  generalInformation_notice_button: "Descargar",

  signUp: "Suscribirse",

  tokenModal_title: "¡Hola, bienvenido!",
  tokenModal_subtitle:
    "Para su seguridad, recibirá un código de autenticación por correo electrónico. Simplemente ingrese el código cuando se le solicite para continuar con su registro para el desafío.",
  tokenModal_input: "Correo electrónico",
  tokenModal_inputMessage:
    "Por favor ingrese un correo electrónico válido para recibir el token de autenticación.",
  tokenModal_button: "Continuar",
  tokenModal_confirm: "Token de autenticación recibido por correo electrónico.",
  tokenModal_end: "Fin",

  startupForm_title: "Registrar inicio",
  startupForm_name: "Nombre",
  startupForm_email: "Correo electrónico",
  startupForm_emailError: "Correo electrónico no válido",
  startupForm_phone: "Teléfono",
  startupForm_gender: "Género",
  startupForm_gender_female: "Mujer",
  startupForm_gender_masculine: "Hombre",
  startupForm_gender_other: "Otro",
  startupForm_gender_placeholder: "Elegir...",
  startupForm_birthdate: "Fecha de nacimiento",
  startupForm_state: "Estado",
  startupForm_city: "Ciudad",
  startupForm_startupName: "Nombre de Startup",
  startupForm_startupSite: "Sitio web de Startup",

  backHome: "Volver a la página de inicio",

  successs: "éxito",
  successMessage: "¡Formulario enviado exitosamente!",
  error: "Error",

  dropdown_text:
    "Identificar puntos de mejora en los procesos operativos encaminados a automatizar y digitalizar procesos, aumentar la eficiencia y reducir el fraude.",
  dropdown_howCanYourProjectHelp_label:
    "¿Cómo puede su proyecto apoyar este desafío?",
  dropdown_howCanYourProjectHelp_helper:
    "En hasta 1000 caracteres, escribe el problema que resuelve tu startup y tu modelo de negocio.",
  dropdown_bussinessModel_label: "Modelo de Negocio",
  dropdown_segment_label: "Segmento",
  dropdown_logo_label: "Logotipo de la empresa",
  dropdown_maturity_label: "Madurez",
  dropdown_presentation_label: "Presentación",
  dropdown_presentation_helper:
    "Pega aquí el enlace de tu presentación para descargarla (Dropbox, Google Drive o similar). /",
  dropdown_presentation_helper_bold: "Vídeo máximo 3 minutos.",
  dropdown_video_label: "Vídeo",
  dropdown_video_helper:
    "Si tienes un vídeo sobre tu negocio, pega el enlace aquí (no olvides hacerlo público)./",
  dropdown_capturingInvestments_label:
    "¿Están captando inversión o tienen intención de abrir una ronda de financiación en 2023?",
  dropdown_capturingPhase_label: "Capturando etapa",
  dropdown_invoicing_label: "Facturación promedio en los últimos 12 meses",
  dropdown_growth_label: "% de crecimiento promedio en los últimos 12 meses",
  yes: "Sí",
  no: "no",
  save: "Guardar",
};
