import { useIntl } from "react-intl";
import "./styles.scss";

function ChallengesBanner({
  banner,
  name,
  subtitle,
  color_secundary,
  logo,
  onclick,
}: any) {
  const intl = useIntl();
  return (
    <div
      className="challenges-banner"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_BASEPATH_STORAGE}/${banner})`,
      }}
    >
      <div className="banner-info">
        <h1>{name}</h1>
        <p>{subtitle}</p>
        <a onClick={onclick} role="button" className="link">
          <div
            className="challenges-button"
            style={{
              backgroundColor: color_secundary,
              border: `1px solid ${color_secundary}`,
            }}
          >
            {intl.formatMessage({ id: "seeChallenges" })}
          </div>
        </a>
      </div>
      <div className="company-logo-circle">
        <img
          src={`${process.env.REACT_APP_BASEPATH_STORAGE}/${logo}`}
          alt="Logo Empresa"
        />
      </div>
    </div>
  );
}

export default ChallengesBanner;
